import React from 'react';
import '../../Pages.css';
import pic1 from '../../../../assets/pretratamiento.png';
import pic2 from '../../../../assets/pretratamiento2.png';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
export default function PretratamientoTratamientosFisicoquimicos() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('pretratamiento_y_tratamientos_fisicoquimicos')}</h1>
                </div>
                <div className='row'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('pretratamiento')}</h1>
                        {parse(t('pretratamiento_text'))}
                    </div>
                    <div className='col-30-70'>
                    </div>
                </div>
                <div className='row'>
                    {/* <div className='col-30-70'>
                        
                    </div> */}
                    <div className='grid container algin-content-right justify'>
                        <img src={pic1} alt={t('pretratamiento')} style={{ width: "50%",  marginLeft: "25%"}}/>
                        <h1>{t('tratamiento_fisicoquimicos')}</h1>
                        {parse(t('tratamiento_fisicoquimicos_text'))}
                    </div>
                </div>
                <div className='row grid container justify'>
                    <div className='algin-content-left justify'>
                        <h1>{t('ventajas')}</h1>
                        {parse(t('pretratamiento_ventajas_text'))}
                    </div>
                    <img src={pic2} alt="Plantas de Tratamiento y Reactores"/>
                </div>
            </div>
        </div>
    )
}