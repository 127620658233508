import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';
export default function RestaurantesCocinaIndustrial() {

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('restaurantes_y_cocina_industrial')}</h1>
                    {parse(t('restaurantes_y_cocina_industrial_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('asesoria_inicial')}</h1>
                        {parse(t('asesoria_inicial_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('muestreo_analisis')}</h1>
                        {parse(t('muestreo_analisis_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{ t('seleccion_e_instalacion_de_equipos')}</h1>
                        {parse(t('seleccion_e_instalacion_de_equipos_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{ t('tratamientos_biologicos_a_base_de_bacterias_y_enzimas')}</h1>
                        {parse(t('tratamientos_biologicos_a_base_de_bacterias_y_enzimas_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{ t('solucion_completa')}</h1>
                        {parse(t('solucion_completa_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}