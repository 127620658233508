import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';
export default function Consultoria() {

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('consultoria_experta')}</h1>
                    {parse(t('consultoria_experta_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('etapa_inicial')}</h1>
                        {parse(t('etapa_inicial_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('etapa_de_consultoria')}</h1>
                        {parse(t('etapa_de_consultoria_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('ejecucion_de_estudios_y_ensayos')}</h1>
                        {parse(t('ejecucion_de_estudios_y_ensayos_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('apooyo_en_auditorias_e_inspecciones')}</h1>
                        {parse(t('apooyo_en_auditorias_e_inspecciones_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('asesoria_juridica')}</h1>
                        {parse(t('asesoria_juridica_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}