import React, { useState } from 'react'
import { t } from 'i18next';
import * as myConst from '../../constants.js';
import './HelperAvatar.css';

export default function HelperAvatar() {
    const [displayBox, setDisplayBox] = useState(false);

    return (
        <>
            {displayBox &&
                <div className='helperFrame'>
                    <div className='card'>
                        <div className='title'>
                            {t('mensaje_hola')}
                            <span onClick={() => setDisplayBox(false)}>
                                <i className="fas fa-times"></i>
                            </span>
                        </div>
                        <div className='body'>
                            <div className='col col-2 avatarProfile'>
                                <img src={myConst.BASE_URL + "/img/avatar.png"} alt='Avatar' />
                            </div>
                            <div className='col col-2-reverse message'>
                                <div className='msg'>
                                    <div className='msgBox'>
                                        {t('mensaje_hola_text')}
                                    </div>
                                    <div className='col col-5'>
                                        <a href='https://api.whatsapp.com/send?phone=5216621425985' target="_blank" className="whatsappButton"><i className="fab fa-whatsapp"></i>WhatsApp</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href='https://m.me/corporativokino' target="_blank" className="messenger"><i class="fab fa-facebook-messenger"></i>Messenger</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href='mailto:ventas@corporativokino.com' target="_blank" className="email"><i class="far fa-envelope"></i>Email</a>
                                    </div>
                                    <div className='col col-5'>
                                        <a href='tel:5216621425985' target="_blank" className="phone"><i class="fas fa-phone"></i>Llamada</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='helper'>
                <div className='avatar' onClick={() => setDisplayBox(true)}>
                    <img src={myConst.BASE_URL + "/img/avatar.png"} alt="Avatar" />
                </div>
            </div>
        </>
    )
}
