import React from 'react';
import '../../Pages.css';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function EquiposMediosFiltrantes() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('equipos_y_medios_filtrantes')}</h1>
                    {parse(t('equipos_y_medios_filtrantes_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('refacciones')}</h1>
                        {parse(t('refacciones_text'))}
                    </div>
                </div>
            </div>
        </div>
    )
}