import React, { useEffect } from 'react';
import '../Pages.css';
import pic1 from '../../../assets/seguridad_y_salud_ocupacional1.jpg';
import pic2 from '../../../assets/seguridad_y_salud_ocupacional2.jpg';
import pic3 from '../../../assets/seguridad_y_salud_ocupacional3.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
// import * as myConst from '../../../constants';

function SeguridadSaludOcupacional() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t('seguridad_y_salud_ocupacional')}</h1>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('seguridad')}</h1>
                        {parse(t('seguridad_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic1} alt="Seguridad_y_salud_ocupacional"/>
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'>
                        <img src={pic2} alt="Seguridad_y_salud_ocupacional" />
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('salud_ocupacional')}</h1>
                        {parse(t('salud_ocupacional_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('ensayos_acreditados_y_aprobados')}</h1>
                        {parse(t('kino_ensayos_acreditados_y_aprobados_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic3} alt="Seguridad_y_salud_ocupacional" />
                    </div>
                </div>
            </div>
        </div >
    )
}

export default SeguridadSaludOcupacional