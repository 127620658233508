import React, { useEffect, useState } from 'react';
import './Pages.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
// import * as myConst from '../../../constants';
import bg_valores from '../../assets/bg_valores.png';
import bg_valores2 from '../../assets/bg_valores2.png';

function ServiciosHolding() {
    const params = useParams();
    const { t, i18n, ready } = useTranslation();
    useEffect(() => {
        //we can check the language here
    }, []);

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t("servicios_de_holding")}</h1>
                </div>
                <div className='row container'>
                    <p>
                        {t("servicios_de_holding_text")}
                    </p>
                </div>
            </div>

            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("administracion_y_finanzas")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t("administracion_y_finanzas_text"))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("comercializacion")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t("comercializacion_text"))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("recursos_humanos")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t("recursos_humanos_text"))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("compliance")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t("compliance_text"))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiciosHolding