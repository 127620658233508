import React from 'react';
import '../../Pages.css';
import pic1 from '../../../../assets/simulacros.jpg';
import pic2 from '../../../../assets/simulacros2.jpg';
import pic3 from '../../../../assets/simulacros3.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
export default function Simulacros() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('simulacros')}</h1>
                    <img src={pic1} alt="Simulacros" />
                    {parse(t('simulacros_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-50 algin-content-left justify'>
                        <img src={pic2} alt="Simulacros"/>
                    </div>
                    <div className='grid container col-50 algin-content-left justify'>
                        <img src={pic3} alt="Simulacros"/>
                    </div>
                </div>
            </div>
        </div>
    )
}