import React from 'react';
import '../Pages.css';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function BombeoInstrumentacion() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t('bombeo_e_instrumentacion')}</h1>
                </div>
                <div className='row grid container justify'>
                    <h1>{t('equipos_de_bombeo')}</h1>
                    {parse(t('equipos_de_bombeo_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-10 algin-content-left justify'>
                        <h1>{t('control_e_instrumentacion')}</h1>
                        {parse(t('equipos_de_bombeo_text'))}
                    </div>
                </div>
            </div>
        </div>
    )
}