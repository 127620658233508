import React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function News() {
    const { t, i18n, ready } = useTranslation();
    return (
        <div className='container'>
            <div className='flow-root'>
                <div className='container no-padding grid1 box-1'>
                    <div className='card'>
                        <h2 className='title-tag'>{t('plantas_tratadoras')}</h2>
                        <img src={'/img/plantas_home.jpg'} title="Fundación" alt="Fundación" />
                        <div className='container'>
                            <h3>{t('plantas_tratadoras_de_agua_residual')}</h3>
                            {parse(t('plantas_tratadoras_text'))}
                        </div>
                    </div>
                </div>
                <div className='container no-padding grid2 box-2'>
                    <div className='card'>
                        <h2 className='title-tag'>{t('corporate')}</h2>

                        <img src={'/img/corporativo_home.jpg'} title="Corporativo" alt="Corporativo" />
                        <div className='container'>
                            <h3>{t('nos_ocupamos_de_tus_problemas_somos_tu_solución')}</h3>
                            {parse(t('nos_ocupamos_de_tus_problemas_somos_tu_solución_text'))}
                        </div>
                    </div>
                </div>
                <div className='container no-padding grid4-2 box-3'>
                    <div className='card'>
                        <h2 className='title-tag'>{t('orientacion')}</h2>
                        <img src={'/img/orientacion_home.jpg'} title="Fundación" alt="Fundación" />
                        <div className='container'>
                        {parse(t('orientacion_text'))}
                        </div>
                    </div>
                </div>
                <div className='container no-padding grid3 box-4'>
                    <div className='card card-horizontal'>
                        <h2 className='title-tag'>{t('medio_ambiente')}</h2>
                        <img src={'/img/medioambiente_home.jpg'} title={t('medio_ambiente')} alt={t('medio_ambiente')} />
                        <div className='container'>
                            {parse(t('news_medio_ambiente'))}
                        </div>
                    </div>
                </div>
                <div className='container no-padding grid3-2 box-5'>
                    <div className='card'>
                        <h2 className='title-tag'>{t('ambiente_laboral')}</h2>
                        <img src={'/img/ambientelaboral_home.jpg'} title="Fundación" alt="Fundación" />
                        <div className='container'>
                        {parse(t('ambiente_laboral_text'))}
                        </div>
                    </div>
                </div>
                <div className='container no-padding grid4 box-6'>
                    <div className='card'>
                        <h2 className='title-tag'>{t('ingenieria')}</h2>
                        <img src={'/img/ingenieria_home.jpg'} title="Ingeniería" alt="Ingeniería" />
                        <div className='container'>
                            <h3>{t('ingenieria_integral')}</h3>
                            {parse(t('ingenieria_integral_text'))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}