import React from "react";
import "./Pages.css";
import { useTranslation } from 'react-i18next';
export default function ContenidoAudiovisual() {
	const [files, setFiles] = React.useState(null);
	const { t, i18n, ready } = useTranslation();
	React.useEffect(function effectFunction() {
		fetch(window.location.origin + "/contenido_audiovisual/videos.json")
			.then((response) => response.json())
			.then((data) => {
				var result = Object.keys(data).map((key) => [key, data[key]]);
				setFiles(result);
			});
	}, []);

	if (files == null) return <p>{t('loading')}...</p>;
	else {
		return (
			<div className="container pages contenido_audiovisual">
				<div className="">
					<div className="row grid container justify">
						<h1 className="primaryTitle">{t('audio_visual_content')}</h1>
						<>
							{files.map((content, index) => {
								if(content[1].length>0)
								return (
									<span key={index}>
										<h2 className="title">{content[0]}</h2>
										<div className="row">
											{content[1].map((item, ind) => {
												return (
													<div key={ind} className="col-5">
														<iframe width={"100%"} height={"400px"} src={item.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
													</div>
												)
											})}
										</div>
									</span>
								)
								else return(<></>)
							})}
						</>
					</div>
				</div>
			</div>
		);
	}
}
