import React from 'react';
import '../../Pages.css';
import pic1 from "../../../../assets/kindeplan_proteccion_tramites_estatales.jpg"
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
export default function TramitesEstatalesProteccion() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('tramites_estateles')}</h1>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('ley_de_proteccion_civil_del_estado_de_sonora')}</h1>
                        {parse(t('ley_de_proteccion_civil_del_estado_de_sonora_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic1} alt="Trámites Estatales"/>
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('estudio_de_riesgo_de_proteccion_civil')}</h1>
                        {parse(t('estudio_de_riesgo_de_proteccion_civil_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('programa_interno_de_proteccion_civil')}</h1>
                        {parse(t('programa_interno_de_proteccion_civil_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}