import React from 'react';
import './HeroSectionCards.css';
import { useTranslation } from "react-i18next";


function HeroSectionCards() {
    const { t, i18n, ready } = useTranslation();
    const [files, setFiles] = React.useState(null)
    React.useEffect(function effectFunction() {
        fetch(window.location.origin + '/descargables/home/files.json')
            .then(response => response.json())
            .then((data) => {
                setFiles(data);
            });
    }, []);

    if (files == null) return (<p></p>)
    else
        return (
            <div className='container our-publish'>
                <div className='row text-center'>
                    <div className='primaryTitle text-center w-100'>{t("our_publication")}</div>
                </div>
                <div className='row'>
                    {files.map((item, index) => {
                        return (
                            <div key={index} className='col col-3 p-30'>
                                <div className={((index + 1) % 2 == 0) ? 'brochure col-height-30 p-45 reverse' : "brochure col-height-30 p-45"}>
                                    <h3>{item.caption.sp}</h3>
                                    <a href={window.location.href + "/descargables/" + item.file} target="_blank">{t("download")}</a>
                                </div>
                            </div>
                        )
                    })
                    }
                </div>
            </div>
        )
}

export default HeroSectionCards