import React from 'react';
import '../../Pages.css';
import pic1 from '../../../../assets/curso_capacitacion_formacion_brigadas.jpg';
import pic2 from '../../../../assets/curso_capacitacion_formacion_brigadas2.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
export default function CursoCapacitacionProteccion() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('curso_de_capacitacion_para_formacion_de_brigadas')}</h1>
                    <img src={pic1} alt={t('curso_de_capacitacion_para_formacion_de_brigadas')} />
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('formacion_de_brigadas')}</h1>
                        {parse(t('formacion_de_brigadas_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic2} alt={t('formacion_de_brigadas')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}