import React from 'react';
import '../../Pages.css';
import consultoriaAmbiental from '../../../../assets/consultoriaAmbiental.jpg';
import pic1 from '../../../../assets//kindeplan_consultoria_ambiental.jpg';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
export default function ConsultoriaAmbiental() {
    const { t, i18n, ready } = useTranslation();
    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1>{t('consultoria_ambiental')}</h1>
                    <img src={consultoriaAmbiental} alt={t('consultoria_ambiental')} />
                    {parse(t('consultoria_ambiental_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('consultoria_personalizada')}</h1>
                        {parse(t('consultoria_personalizada_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic1} alt={t('consultoria_ambiental')}/>
                    </div>
                </div>
            </div>
        </div>
    )
}