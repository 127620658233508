// import { Carousel } from 'react-carousel-minimal';
import React from 'react';
import './Carousel.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useTranslation } from "react-i18next";

function CarouselSection() {

    const { t, i18n, ready } = useTranslation();
    console.log("i18n.language")
    console.log(i18n.language)
    const [slider, setSliders] = React.useState(null)
    React.useEffect(function effectFunction() {
        fetch(window.location.href + '/img/slider/slider.json')
            .then(response => response.json())
            .then((data) => {
                setSliders(data);
            });
    }, []);

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }
    if (slider == null) return (<div style={{ height: 600 }}></div>)
    else
        return (
            <Carousel className='sliderContent' showArrows={true} showStatus={false} emulateTouch showThumbs={false} autoPlay={true} transitionTime={2000} interval={8000} infiniteLoop={true}>
                {slider.map((item, index) => {
                    return (
                        <span key={index}>
                            <img className='images_slider' src={item.image} />
                            <h1>{i18n.language=="en" ? item.caption.en :item.caption.sp}</h1>
                        </span>
                    )
                })
                }
            </Carousel>
        );
}

export default CarouselSection;