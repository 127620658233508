import React from 'react';
import '../../Pages.css';
import pic1 from '../../../../assets/kindeplan_tramites_federales.jpg';
import medioambiente1 from '../../../../assets/tramites_federales2.jpg';
import medioambiente2 from '../../../../assets/tramites_federales3.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function TramitesFederales() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='marginBottom20'>{t('tramites_federales')}</h1>
                    {parse(t('tramites_federales_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'>
                        <img src={pic1} alt="trámites federales"/>
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('expertos_ambientales')}</h1>
                        {parse(t('expertos_ambientales_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('impactos_ambientales')}</h1>
                        {parse(t('impactos_ambientales_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={medioambiente1} />
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'>
                        <img src={medioambiente2} />
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('residuos_peligrosos')}</h1>
                        {parse(t('residuos_peligrosos_text'))}
                    </div>
                </div>
            </div>
        </div>
    )
}