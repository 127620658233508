import { t } from 'i18next';
import React from 'react';
import parse from 'html-react-parser';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            <div className="footer">
                <div className="row">
                    <div className="col col-5">
                        <div className="footer-text text-note">
                            <h1>{t('direccion')}</h1>
                            <p><i className={'fas fa-map'} /> {parse(t('direccion_text'))}</p>
                            <p><i className="fas fa-phone"></i> <a href={"tel:"+t('telefono_number')} style={{color: "#fff"}}>{t('telefono_number')}</a></p>
                        </div>
                    </div>
                    <div className="col col-5">
                        <div className="footer-text text-note">
                            <h1>{t('otros_enlaces')}</h1>
                            <p>
                                <Link to={'/'+t("sugerencias_link")}  style={{color: "#fff"}} >
                                    {t('sugerencias_quejas_felicitaciones_title')}
                                </Link>
                                {/* <a href={""}></a> */}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-3"></div>
                    <div className="col col-3">
                        <div className="footer-text text-note">
                            {t('footer_text')}
                        </div>
                    </div>
                    <div className="col col-3"></div>
                </div>
                <div className="row">
                    <div className="col col-10">
                        <a href="https://www.facebook.com/corporativokino" target="_blank" rel='noreferrer'>
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://www.instagram.com/corporativokino/" target="_blank" rel='noreferrer'>
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="https://www.tiktok.com/@corporativokino" target="_blank" rel='noreferrer'>
                            <i className="fab fa-tiktok"></i>
                        </a>
                        <a href="https://www.linkedin.com/company/corporativo-kino" target="_blank" rel='noreferrer'>
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                        <a href="https://www.youtube.com/channel/UCDolzvGsS14qgx7jrcLDVJQ" target="_blank" rel='noreferrer'>
                            <i className="fab fa-youtube"></i>
                        </a>
                        <a href="https://twitter.com/corporativokino" target="_blank" rel='noreferrer'>
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href='https://api.whatsapp.com/send?phone=5216621425985' target="_blank">
                            <i className="fab fa-whatsapp"></i>
                        </a>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-10">
                        <div className="footer-text">
                            <p>corporativokino.com</p>
                            <p>{t('desarrollado_por')} <a href="https://ramico.com.mx"><span className="ra">ra</span><span className="mi">mi</span><span className="co">co</span></a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer