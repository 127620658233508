import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import './Pages.css';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
// import * as myConst from '../../../constants';
import bg_valores from '../../assets/bg_valores.png';
import bg_valores2 from '../../assets/bg_valores2.png';

function FilosofiaOrganizacional() {
    const params = useParams();
    const { t, i18n, ready } = useTranslation();
    useEffect(() => {
        //we can check the language here
    }, []);

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t('filosofia_organizacional')}</h1>
                </div>
                <div className='row container'>
                    <p>
                        {t('filosofia_organizacional_text')}
                    </p>
                </div>
            </div>

            <div className='col col-5'>
                <div className='card card-no-border brochure reverse col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t('mision')}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t('mision_text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-5'>
                <div className='card card-no-border brochure col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t('vision')}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t('vision_text')}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("valores")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t("valores_text"))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t("unidades_de_negocio")}</h1>
                </div>
                <div className='row container'>
                    <p>
                        {t("unidades_de_negocio_text")}
                    </p>
                </div>
            </div>

            <div className='row marginTop20 bg-section' style={{ backgroundImage: `url(${bg_valores})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                <img src='/img/sustentec.png' alt="sustentec" title="sustentec" width="200" />
            </div>
            <div className='col col-5'>
                <div className='card card-no-border col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t("mision")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("sustentec_mision_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-5'>
                <div className='card card-no-border col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t("vision")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("sustentec_vision_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row marginTop20 bg-section' style={{ backgroundImage: `url(${bg_valores2})`, backgroundSize: "cover", backgroundPosition: 'center', textAlign: "left" }}>
                <div className='content'>
                    <img src='/img/kindeplan.png' alt="Kindeplan" title="kindeplan" width="200" />
                </div>
            </div>
            <div className='col col-5'>
                <div className='card card-no-border col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t("mision")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("kindeplan_mision_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-5'>
                <div className='card card-no-border col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t("vision")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("kindeplan_vision_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilosofiaOrganizacional