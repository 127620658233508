import React from 'react';
import '../Pages.css';
// import * as myConst from '../../../constants';
import medioambienteresiduos from '../../../assets/medioambiente.jpg';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

function MedioAmbienteResiduos() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t("medio_ambiente_y_residuos")}</h1>
                </div>
                <div className='row grid container'>
                    <h1>{t("medio_ambiente")}</h1>
                    {parse(t("medio_ambiente_text"))}
                </div>
                <div className='row grid container'>
                    <h1>{t("residuos")}</h1>
                    <img src={medioambienteresiduos} alt="pic" />
                    {parse(t("residuos_text"))}
                </div>
                <div className='row grid container'>
                    <h1>{t("ensayos_acreditados_y_aprobados")}</h1>
                    {parse(t("ensayos_acreditados_y_aprobados_text"))}
                </div>
            </div>
        </div>
    )
}

export default MedioAmbienteResiduos