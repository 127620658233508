import React from 'react';
import '../../Pages.css';
import pic1 from "../../../../assets/bacterias.jpg";
import pic2 from "../../../../assets/bacterias2.jpg";
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
export default function BacteriasEnzimas() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('bacterias_y_enzimas')}</h1>
                    {parse(t('bacterias_y_enzimas_text'))}
                </div>
                <div className='row'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('en_plantas_de_tratamiento')}</h1>
                        {parse(t('en_plantas_de_tratamiento_text'))}
                    </div>
                    <div className='col-30-70'>
                        <img src={pic1} alt={t('bacterias_y_enzimas')}/>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-30-70'>
                        <img src={pic2} alt={t('bacterias_y_enzimas')}/>
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('en_tuberias_y_trampas_de_grasas_y_aceites')}</h1>
                        {parse(t('en_tuberias_y_trampas_de_grasas_y_aceites_text'))}
                    </div>
                </div>
            </div>
        </div>
    )
}