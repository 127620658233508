import React from 'react';
import '../../Pages.css';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function SeparadorHidrocarburos() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('separador_hidrocarburos')}</h1>
                    {parse(t('separador_hidrocarburos_text'))}
                </div>
            </div>
        </div>
    )
}