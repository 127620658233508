import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';
export default function AgenteQuimicos() {
    

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('restaurantes_y_cocina_industrial')}</h1>
                    {parse(t('agentes_quimicos_en_el_ambiente_laboral_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('expertos_en_reconocimiento')}</h1>
                        {parse(t('expertos_en_reconocimiento_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('asesoria_profesional')}</h1>
                        {parse(t('asesoria_profesional_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('planificacion_experta')}</h1>
                        {parse(t('planificacion_experta_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('mayor_alcance_en_la_ejecucion')}</h1>
                        {parse(t('mayor_alcance_en_la_ejecucion_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('interpretacion_de_los_resultados')}</h1>
                        {parse(t('interpretacion_de_los_resultados_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}