import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';

export default function Agua() {

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('situacion_actual')}</h1>
                    {parse(t('situacion_actual_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('expertos_gestion')}</h1>
                        {parse(t('expertos_gestion_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('permisos_ambientales')}</h1>
                        {parse(t('permisos_ambientales_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('control_calidad_agua')}</h1>
                        {parse(t('control_calidad_agua_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('tratamiento_agua')}</h1>
                        {parse(t('tratamiento_agua_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('asesoria_juridica')}</h1>
                        {parse(t('asesoria_juridica_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}