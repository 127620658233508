import React from 'react';
import '../../Pages.css';
import cursoAmbiental from '../../../../assets/cursoAmbiental.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function CursosAmbientales() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='marginBottom20'>{t('cursos_ambientales')}</h1>
                    <img src={cursoAmbiental} />
                    {parse(t('cursos_ambientales_text'))}
            
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('formacion_de_líderes_en_la_materia')}</h1>
                        {parse(t('formacion_de_líderes_en_la_materia_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}