import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';

export default function Mineria() {
    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('un_corporativo_hecho_para_la_minería')}</h1>
                    { parse(t('un_corporativo_hecho_para_la_minería_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('personal_capacitado')}</h1>
                        {parse(t('personal_capacitado_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'></div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('unidades_vehiculares_preparadas')}</h1>
                        {parse(t('unidades_vehiculares_preparadas_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('servicios_integrados_a_la_minería')}</h1>
                        {parse(t('servicios_integrados_a_la_minería_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}