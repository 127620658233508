import React from 'react';
import '../../Pages.css';
import cursocapacitacion from '../../../../assets/cursos_capacitacion_seguridad_higiene.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function CursoCapacitacion() {
    const { t, i18n, ready } = useTranslation();
    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('cursos_de_capacitacion_en_seguridad_e_higiene')}</h1>
                    <img src={cursocapacitacion} alt={cursocapacitacion} />
                    {parse(t("cursos_de_capacitacion_en_seguridad_e_higiene_text"))}
                </div>
            </div>
        </div>
    )
}