import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ImageSection() {
const { t, i18n, ready } = useTranslation();
    return (
        <div className='our-publish vertical-center imageSection'>
            <h1>{t('image_section_text')}</h1>
        </div>
    )
}