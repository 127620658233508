import React from 'react';
import './Pages.css';
import { t } from 'i18next';
import parse from 'html-react-parser';

export default function Residuos() {

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('analisis_y_clasificacion')}</h1>
                    {parse(t('analisis_y_clasificacion_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('la_correcta_seleccion_para_la_recoleccion_de_los_residuos')}</h1>
                        {parse(t('la_correcta_seleccion_para_la_recoleccion_de_los_residuos_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}