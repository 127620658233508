import React from 'react';
import '../../App.css';
import CarouselSection from '../Carousel/CarouselSection';
import HeroSection from '../HeroSection/HeroSection';
import HeroSectionCards from '../HeroSectionCards/HeroSectionCards';
import ImageSection from './imageSection';
import News from './News';
import './Home.css';


export default function Home() {
    return (
        <>
            {/* <SliderSection /> */}
            <div className='lines'></div>
            <div className='lines lines2'></div>
            <div className='lines lines3'></div>
            <CarouselSection />
            <div className='contentScroll'>
                <News />
                <ImageSection />
                <HeroSection />
                <HeroSectionCards />
            </div>
        </>
    )
}
