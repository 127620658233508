import React from 'react';
import '../../Pages.css';
import pic1 from '../../../../assets/planta_tratamiento.jpg';
import pic2 from '../../../../assets/planta_tratamiento2.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function PlantasTratamiento() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('plantas_de_tratamiento_y_reactores')}</h1>
                    <img src={pic1} alt={t('plantas_de_tratamiento_y_reactores')}/>
                </div>
                <div className='row'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('diseño_y_fabricacion_de_plantas_de_tratamiento')}</h1>
                        {parse(t('diseño_y_fabricacion_de_plantas_de_tratamiento_text'))}
                    </div>
                    <div className='col-30-70'>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-30-70'>
                        {/* <img src={pic2} alt="Plantas de Tratamiento y Reactores"/> */}
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('sistema_de_tratamiento')}</h1>
                        {parse(t('sistema_de_tratamiento_text'))}
                    </div>
                </div>
                <div className='row grid container justify'>
                    <div className='algin-content-left justify'>
                        <h1>{t('ventajas')}</h1>
                        {parse(t('ventajas_text'))}
                    </div>
                    <img src={pic2} alt={t('sistema_de_tratamiento')} />
                </div>
            </div>
        </div>
    )
}