import React from 'react';
import '../../Pages.css';
import pic from "../../../../assets/kindeplan_tramites_estatales2.jpg"
import pic2 from '../../../../assets/kindeplan_tramites_estatales.jpg';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';
export default function TramitesEstatales() {
    const { t, i18n, ready } = useTranslation();
    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='marginBottom20'>{t('tramites_estateles')}</h1>
                    <img src={pic} alt={t('tramites_estateles')} />
                    {parse(t('tramites_estateles_text'))}
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('relaciones_publicas')}</h1>
                        {parse(t('relaciones_publicas_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
                <div className='row marginTop20'>
                    <div className='col-30-70'>
                        <img src={pic2} alt="trámites estatales"/>
                    </div>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('auditorias_ambientales_y_atencion_a_inspecciones')}</h1>
                        {parse(t('auditorias_ambientales_y_atencion_a_inspecciones_text'))}
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        <h1>{t('derecho_ambiental')}</h1>
                        {parse(t('derecho_ambiental_text'))}
                    </div>
                    <div className='col-30-70'></div>
                </div>
            </div>
        </div>
    )
}