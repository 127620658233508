import React, { useEffect, useState } from 'react';
import './Pages.css';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';


function Antecedentes() {
    const params = useParams();
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='row marginTop20'>
                <h1 className='primaryTitle'>{t("nuestros_inicios")}</h1>
            </div>
            <div className='col col-60-40'>
                <div className='card card-no-border brochure reverse col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t("inicios")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("inicio_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-40-60'>
                <div className='card card-no-border brochure col-height-30 p-45'>
                    <div className='row'>
                        <h2>{ t("corporativo")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            <p>
                                {t("antecedentes_corporativo_text")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t("crecimiento")}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t('antecedentes_crecimiento_text'))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Antecedentes