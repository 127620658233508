import React from "react";
import { useTranslation } from 'react-i18next';
import "./Pages.css";
import axios from "axios";

export default function Sugerencias() {
	const [dataForm, setDataForm] = React.useState({name: "",tema: "", correo:"",puesto: "", empresa: "", mensaje: ""});
	const [isEmailSended, setIsEmailSended] = React.useState(false);
	const { t } = useTranslation();

	window.scrollTo(0, 0);
	
	const SendButton = () => {
			return (
				<button
				className={(dataForm.name=="" || dataForm.tema=="" || dataForm.correo=="" || dataForm.mensaje=="") ? "sendForm disabledBtn" :  "sendForm"}
					onClick={() => sendEmail()}
					disabled={(dataForm.name=="" || dataForm.tema=="" || dataForm.correo=="" || dataForm.mensaje=="")}
				>
					{t('enviar')}
				</button>
			);
	};

	const sendEmail = async () => {
		let result = await axios.post("https://apifutbol.ramico.com.mx/index.php/EmailService/",{dataForm})
		setIsEmailSended(true)
		await setDataForm({name: "",tema: "", correo:"",puesto: "", empresa: "", mensaje: ""})
	}

	return (
		<div className="container pages descargables">
			<div className="wrapSection">
				<div className="row grid container justify">
					{isEmailSended ?
						<>
							<h1 className="primaryTitle">{t('gracias_titulo')}</h1>
							<p style={{textAlign:"center"}}>{t('gracias_text')}</p>
						</>
					:
						<>
						<h1 className="primaryTitle">{t('sugerencias_quejas_felicitaciones_title')}</h1>
						<>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('tema')}</label>
								<select className="selectOpt" required onChange={(e)=> setDataForm(prevState => ({ ...prevState, tema: e.target.value }))}>
									<option key={0} value={""} selected={dataForm.tema==""}>
										{t('seleccionar_opcion')}
									</option>
									<option key={1} value={t('sugerencias')} selected={dataForm.tema=="sugerencias"}>
										{t('sugerencias')}
									</option>
									<option key={2} value={t('quejas')} selected={dataForm.tema=="quejas"}>
										{t('quejas')}
									</option>
									<option key={3} value={t('felicitaciones')} selected={dataForm.tema=="felicitaciones"}>
										{t('felicitaciones')}
									</option>
								</select>
							</div>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('nombre')}</label>
								<input type="text" class="inputText" value={dataForm.name} placeholder="" required onChange={(e)=> setDataForm(prevState => ({ ...prevState, name: e.target.value }))} />
							</div>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('puesto')}</label>
								<input type="text" class="inputText" id="exampleFormControlInput3" value={dataForm.puesto} placeholder="" onChange={(e)=> setDataForm(prevState => ({ ...prevState, puesto: e.target.value }))} />
							</div>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('empresa')}</label>
								<input type="text" value={dataForm.empresa} class="inputText" id="exampleFormControlInput4" placeholder="" onChange={(e)=> setDataForm(prevState => ({ ...prevState, empresa: e.target.value }))} />
							</div>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('correo')}</label>
								<input type="email" value={dataForm.correo} class="inputText" id="exampleFormControlInput5" placeholder="" required onChange={(e)=> setDataForm(prevState => ({ ...prevState, correo: e.target.value }))} />
							</div>
							<div class="form-group" style={{display: "inherit",padding: "10px"}}>
								<label>{t('mensaje')}</label>
								<textarea type="text" value={dataForm.mensaje} class="inputText" id="exampleFormControlInput6" placeholder="" required onChange={(e)=> setDataForm(prevState => ({ ...prevState, mensaje: e.target.value }))} />
							</div>
						</>
						<SendButton />
						</>
					}
				</div>
			</div>
		</div>
	);
}
