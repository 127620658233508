import React from 'react';
import './Pages.css';
import { useTranslation } from "react-i18next";
import parse from 'html-react-parser';

function Fundacion() {
    const { t, i18n, ready } = useTranslation();
    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row marginTop20'>
                    <h1 className='primaryTitle'>{t('fundacion')}</h1>
                </div>
                <div className='row container'>
                    {parse(t('fundacion_text'))}
                </div>
            </div>

            <div className='col col-10'>
                <div className='card card-no-border brochure reverse col-height-30 p-45'>
                    <div className='row'>
                        <h2>{t('proposito_de_la_fundacion')}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t('proposito_de_la_fundacion_text'))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-10'>
                <div className='card card-no-border'>
                    <div className='row'>
                        <h2>{t('programas_permanentes')}</h2>
                    </div>
                    <div className='row'>
                        <div className='col col-10'>
                            {parse(t('programas_permanentes_text'))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Fundacion