import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from "react-i18next";

function Navbar() {
    const { t, i18n, ready } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        window.location.href=window.location.origin
    };

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        setClick(false)
    };
    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);

    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                        <img src='/img/navbar_logo.png' alt="YoDesarrollo" title="yoDesarrollo" width="200" />
                    </Link>
                    <div className={click ? 'menu' : 'navbar-container'}>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <a className={'nav-links'} >
                                    {t('corporate')}
                                </a>
                                <ul className="submenu">
                                    <Link to={'/corporativo/'+t('antecedentes_link')} onClick={closeMobileMenu}>
                                        <li>
                                            {t('antecedentes')}
                                        </li>
                                    </Link>
                                    <Link to={'/corporativo/'+t('filosofia_organizacional_link')} onClick={closeMobileMenu}>
                                        <li>
                                            {t('nuestra_mision_vision_valores')}
                                        </li>
                                    </Link>
                                    <Link to={'/corporativo/'+t("servicios_de_holding_link")} onClick={closeMobileMenu}>
                                        <li>
                                            {t('servicios_del_holding')}
                                        </li>
                                    </Link>
                                    <Link to={'/corporativo/'+t('fundacion_link')} onClick={closeMobileMenu}>
                                        <li>
                                            {t('fundacion')}
                                        </li>
                                    </Link>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('kino_laboratorios')}
                                </a>
                                <ul className="submenu">
                                    <Link to={'/kinoLaboratorios/'+t("medio_ambiente_y_residuos_link")} onClick={closeMobileMenu}>
                                        <li>
                                            {t('medio_ambiente_y_residuos')}
                                        </li>
                                    </Link>
                                    <Link to={'/kinoLaboratorios/'+t('seguridad_y_salud_ocupacional_link')} onClick={closeMobileMenu}>
                                        <li>
                                            {t('seguridad_y_salud_ocupacional')}
                                        </li>
                                    </Link>
                                    <Link to={'/kinoLaboratorios/'+t('politicas_link')} onClick={closeMobileMenu}>
                                        <li>
                                            {t('politicas')}
                                        </li>
                                    </Link>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('kindeplan')}
                                </a>
                                <ul className="submenu">
                                    <li>
                                        <a>{t('medio_ambiente')}</a>
                                        <ul className="sub-submenu">
                                            <Link to={'/kindeplan/'+t("medio_ambiente_link")+'/'+t("kindeplan_consultoria_ambiental_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('consultoria_ambiental')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("medio_ambiente_link")+'/'+t("kindeplan_tramites_estateles_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('tramites_estateles')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("medio_ambiente_link")+'/'+t("kindeplan_tramites_federales_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('tramites_federales')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("medio_ambiente_link")+'/'+t("kindeplan_cursos_ambientales_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('cursos_ambientales')}
                                                </li>
                                            </Link>
                                        </ul>
                                    </li>
                                    <li>
                                        <a>{t('seguridad_e_higiene')}</a>
                                        <ul className="sub-submenu">
                                            <Link to={'/kindeplan/'+t("seguridad_e_higiene_link")+'/'+t("kindeplan_estudios_de_seguridad_e_higiene_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('estudios_de_seguridad_e_higiene')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("seguridad_e_higiene_link")+'/'+t("kindeplan_cursos_de_capacitacion_en_seguridad_e_higiene_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('cursos_de_capacitacion_en_seguridad_e_higiene')}
                                                </li>
                                            </Link>
                                        </ul>
                                    </li>
                                    <li>
                                        <a>{t('proteccion_civil')}</a>
                                        <ul className="sub-submenu">
                                            <Link to={'/kindeplan/'+t("proteccion_civil_link")+'/'+t("kindeplan_tramites_estateles_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('tramites_estateles')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("proteccion_civil_link")+'/'+t("kindeplan_cursos_de_capacitacion_para_formacion_de_brigadas_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('cursos_de_capacitacion_para_formacion_de_brigadas')}
                                                </li>
                                            </Link>
                                            <Link to={'/kindeplan/'+t("proteccion_civil_link")+'/'+t("kindeplan_simulacros_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('simulacros')}
                                                </li>
                                            </Link>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' href >
                                    {t('sustentec')}
                                </a>
                                <ul className="submenu">
                                    <li>
                                        <a href>{t('tratamiento_de_agua_residual')}</a>
                                        <ul className="sub-submenu">
                                            <Link to={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t("sustentec_pretratamiento_y_tratamientos_fisicoquimicos_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('pretratamiento_y_tratamientos_fisicoquimicos')}
                                                </li>
                                            </Link>
                                            <Link to={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t("sustentec_plantas_de_tratamiento_y_reactores_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('plantas_de_tratamiento_y_reactores')}
                                                </li>
                                            </Link>
                                            <Link to={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t("sustentec_bacterias_y_enzimas_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('bacterias_y_enzimas')}
                                                </li>
                                            </Link>
                                            <Link to={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t("sustentec_separador_hidrocarburos_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('separador_hidrocarburos')}
                                                </li>
                                            </Link>
                                        </ul>
                                    </li>
                                    <li>
                                        <a>{t('potabilizacion_y_purificacion')}</a>
                                        <ul className="sub-submenu">
                                            <Link to={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t("sustentec_equipos_y_medios_filtrantes_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('equipos_y_medios_filtrantes')}
                                                </li>
                                            </Link>
                                            <Link to={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t("sustentec_plantas_de_osmosis_inversa_link")} onClick={closeMobileMenu}>
                                                <li>
                                                    {t('plantas_de_osmosis_inversa')}
                                                </li>
                                            </Link>
                                            {/* <li>
                                                <Link to='/sustentec/potabilizacion_y_purificacion/bebederos_y_estaciones_de_agua' onClick={closeMobileMenu}>{t('bebederos_y_estaciones_de_agua')}</Link>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <Link to={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t("sustentec_bombeo_e_instrumentacion_link")} onClick={closeMobileMenu}>
                                        <li>
                                            {t('bombeo_e_instrumentacion')}
                                        </li>
                                    </Link>
                                    <Link to={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t("sustentec_tanques_y_depositos_modulares_link")} onClick={closeMobileMenu}>
                                        <li>
                                            {t('tanques_y_depositos_modulares')}
                                        </li>
                                    </Link>
                                </ul>
                            </li>
                        </ul>
                        <ul className={click ? 'nav-menu active active2' : 'nav-menu nav-menu2'}>
                            <li className='nav-item'>
                                <Link className='nav-links' to={'/'+t("descargables_link")} onClick={closeMobileMenu}>
                                    {t('downloadable')}
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link className='nav-links' to={'/'+t("contenido_audiovisual_link")} onClick={closeMobileMenu}>
                                    {t('audio_visual_content')}
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('collaborators')}
                                </a>
                                <ul className="submenu">
                                    <a href='https://www.office.com/' target={"_blank"}>
                                        <li>
                                            {t('shared_file')}
                                        </li>
                                    </a>
                                    <a href='https://kino.saiweb.mx/' target={"_blank"}>
                                        <li>
                                            {t('ERP_CRM')}
                                        </li>
                                    </a>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('clients')}
                                </a>
                                <ul className="submenu">
                                    <li>{t('programa_de_fidelidad')}</li>
                                    <a href='https://kino.saiweb.mx:8080/saimovil/defaulte.aspx' target={"_blank"} >
                                        <li>
                                            {t('portal_de_clientes')}
                                        </li>
                                    </a>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('providers')}
                                </a>
                                <ul className="submenu">
                                    <a href='https://kino.saiweb.mx:8080/saimovil/defaulte.aspx' target={"_blank"}>
                                        <li>
                                            {t('portal_de_proveedores')}
                                        </li>
                                    </a>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' >
                                    {t('social_network')}
                                </a>
                                <ul className="submenu">
                                    <a href="https://www.facebook.com/corporativokino" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-facebook-f"></i> Facebook
                                        </li>
                                    </a>
                                    <a href="https://www.instagram.com/corporativokino/" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-instagram"></i> Instagram
                                        </li>
                                    </a>
                                    <a href="https://www.tiktok.com/@corporativokino" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-tiktok"></i> TikTok
                                        </li>
                                    </a>
                                    <a href="https://www.linkedin.com/company/corporativo-kino" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-linkedin-in"></i> Linked in
                                        </li>
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCDolzvGsS14qgx7jrcLDVJQ" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-youtube"></i> Youtube
                                        </li>
                                    </a>
                                    <a href="https://twitter.com/corporativokino" target="_blank" rel='noreferrer'>
                                        <li>
                                            <i className="fab fa-twitter"></i> Twitter
                                        </li>
                                    </a>
                                    <a href='https://api.whatsapp.com/send?phone=5216621425985' target="_blank">
                                        <li>
                                            <i className="fab fa-whatsapp"></i> Whatsapp
                                        </li>
                                    </a>
                                </ul>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' onClick={() => changeLanguage("en")}>
                                    {t('en')}
                                </a>
                            </li>
                            <li className='nav-item'>
                                <a className='nav-links' onClick={() => changeLanguage("es")}>
                                    {t('sp')}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
                    </div>
                    {/* {button && <Button buttonStyle={'btn--outline'}>Registrarse</Button>} */}
                </div>
            </nav>
        </>
    )
}

export default Navbar;