import React from 'react';
import '../Pages.css';
import politicas from "../../../assets/politicas.jpg";
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
function Politicas() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('politicas')}</h1>
                    <img src={politicas} alt={t('politicas')}/>
                </div>
                <div className='row grid container'>
                    {parse(t('politicas_text'))}
                </div>
                <div className='col col-40-60'>
                    <div className='card card-no-border brochure reverse col-height-30 p-45'>
                        <div className='row'>
                            <h2>{t('politica_de_calidad')}</h2>
                        </div>
                        <div className='row'>
                            <div className='col col-10 justify'>
                                {parse(t('politica_de_calidad_text'))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col col-60-40'>
                    <div className='card card-no-border brochure col-height-30 p-45'>
                        <div className='row'>
                            <h2>{t('politica_de_imparcialidad_y_confidencialidad')}</h2>
                        </div>
                        <div className='row'>
                            <div className='col col-10 justify'>
                                {parse(t('politica_de_imparcialidad_y_confidencialidad_text'))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Politicas