import React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home';
// import Modelo from './components/pages/Modelo/Modelo';
import Footer from './components/Footer/Footer';
import HelperAvatar from './components/HelperAvatar/HelperAvatar';
import Antecedentes from './components/pages/Antecedentes';
import FilosofiaOrganizacional from './components/pages/FilosofiaOrganizacional';
import ServiciosHolding from './components/pages/ServiciosHolding';
import Fundacion from './components/pages/Fundacion';
import MedioAmbienteResiduos from './components/pages/KinoLaboratorios/MedioAmbienteResiduos';
import Politicas from './components/pages/KinoLaboratorios/Politicas';
import SeguridadSaludOcupacional from './components/pages/KinoLaboratorios/SeguridadSaludOcupacional';
import ConsultoriaAmbiental from './components/pages/Kindeplan/MedioAmbiente/ConsultoriaAmbiental';
import TramitesEstatales from './components/pages/Kindeplan/MedioAmbiente/TramitesEstatales';
import TramitesFederales from './components/pages/Kindeplan/MedioAmbiente/TramitesFederales';
import CursosAmbientales from './components/pages/Kindeplan/MedioAmbiente/CursosAmbientales';
import EstudioSeguridadHigiene from './components/pages/Kindeplan/SeguridadHigiene/EstudioSeguridadHigiene';
import CursoCapacitacion from './components/pages/Kindeplan/SeguridadHigiene/CursoCapacitacion';
import TramitesEstatalesProteccion from './components/pages/Kindeplan/ProteccionCivil/TramitesEstatales';
import CursoCapacitacionProteccion from './components/pages/Kindeplan/ProteccionCivil/CursoCapacitacion';
import Simulacros from './components/pages/Kindeplan/ProteccionCivil/Simulacros';
import PlantasTratamiento from './components/pages/Sustentec/TratamientoAguaResidual/PlantasTratamiento';
import EquiposMediosFiltrantes from './components/pages/Sustentec/PotabilizacionPurificacion/EquiposMediosFiltrantes';
import PlantasOsmosisInversa from './components/pages/Sustentec/PotabilizacionPurificacion/PlantasOsmosisInversa';
// import BebederosEstacionesAgua from './components/pages/Sustentec/PotabilizacionPurificacion/BebederosEstacionesAgua';
import BombeoInstrumentacion from './components/pages/Sustentec/BombeoInstrumentacion';
import BacteriasEnzimas from './components/pages/Sustentec/TratamientoAguaResidual/BacteriasEnzimas';
import Consultoria from './components/pages/Consultoria';
import Agua from './components/pages/Agua';
import AgenteQuimicos from './components/pages/AgenteQuimicos';
import Residuos from './components/pages/Residuos';
import RestaurantesCocinaIndustrial from './components/pages/RestaurantesCocinaIndustrial';
import Mineria from './components/pages/Mineria';
import Descargables from './components/pages/Descargables';
import ContenidoAudiovisual from './components/pages/ContenidoAudiovisual';
import TanquesDepositosModulares from './components/pages/Sustentec/PotabilizacionPurificacion/TanquesDepositosModulares';
import PretratamientoTratamientosFisicoquimicos from './components/pages/Sustentec/TratamientoAguaResidual/PretratamientoTratamientosFisicoquimicos';
import SeparadorHidrocarburos from './components/pages/Sustentec/TratamientoAguaResidual/SeparadorHidrocarburos';

import { useTranslation } from "react-i18next";
import Sugerencias from './components/pages/Sugerencias';
function App() {
  const { t, i18n, ready } = useTranslation();
  window.scrollTo(0, 0);
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path={'/corporativo/'+t('antecedentes_link')} exact element={<Antecedentes />} />
          {/* <Route path='/corporativo/our_beginnings' exact element={<Antecedentes />} /> */}
          <Route path={'/corporativo/'+t('filosofia_organizacional_link')} exact element={<FilosofiaOrganizacional />} />
          <Route path={'/corporativo/'+t("servicios_de_holding_link")} exact element={<ServiciosHolding />} />
          <Route path={'/corporativo/'+t('fundacion_link')} exact element={<Fundacion />} />
          <Route path={'/kinoLaboratorios/'+t("medio_ambiente_y_residuos_link")} exact element={<MedioAmbienteResiduos />} />
          <Route path={'/kinoLaboratorios/'+t('seguridad_y_salud_ocupacional_link')} exact element={<SeguridadSaludOcupacional />} />
          <Route path={'/kinoLaboratorios/'+t('politicas_link')} exact element={<Politicas />} />
          <Route path={'/kindeplan/'+t('medio_ambiente_link')+'/'+t('kindeplan_consultoria_ambiental_link')} exact element={<ConsultoriaAmbiental />} />

          <Route path={'/kindeplan/'+t('medio_ambiente_link')+'/'+t('kindeplan_tramites_estateles_link')} exact element={<TramitesEstatales />} />
          <Route path={'/kindeplan/'+t('medio_ambiente_link')+'/'+t('kindeplan_tramites_federales_link')} exact element={<TramitesFederales />} />
          <Route path={'/kindeplan/'+t('medio_ambiente_link')+'/'+t('kindeplan_cursos_ambientales_link')} exact element={<CursosAmbientales />} />
          <Route path={'/kindeplan/'+t('seguridad_e_higiene_link')+'/'+t('kindeplan_estudios_de_seguridad_e_higiene_link')} exact element={<EstudioSeguridadHigiene />} />
          <Route path={'/kindeplan/'+t('seguridad_e_higiene_link')+'/'+t('kindeplan_cursos_de_capacitacion_en_seguridad_e_higiene_link')} exact element={<CursoCapacitacion />} />
          <Route path={'/kindeplan/'+t("proteccion_civil_link")+'/'+t('kindeplan_tramites_estateles_link')} exact element={<TramitesEstatalesProteccion />} />
          <Route path={'/kindeplan/'+t("proteccion_civil_link")+'/'+t('kindeplan_cursos_de_capacitacion_para_formacion_de_brigadas_link')} exact element={<CursoCapacitacionProteccion />} />
          <Route path={'/kindeplan/'+t("proteccion_civil_link")+'/'+t('kindeplan_simulacros_link')} exact element={<Simulacros />} />
          <Route path={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t('sustentec_plantas_de_tratamiento_y_reactores_link')} exact element={<PlantasTratamiento />} />
          <Route path={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t('sustentec_bacterias_y_enzimas_link')} exact element={<BacteriasEnzimas />} />
          <Route path={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t('sustentec_separador_hidrocarburos_link')} exact element={<SeparadorHidrocarburos />} />
          <Route path={'/sustentec/'+t("tratamiento_de_agua_residual_link")+'/'+t('sustentec_pretratamiento_y_tratamientos_fisicoquimicos_link')} exact element={<PretratamientoTratamientosFisicoquimicos />} />
          <Route path={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t('sustentec_equipos_y_medios_filtrantes_link')} exact element={<EquiposMediosFiltrantes />} />
          <Route path={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t('sustentec_plantas_de_osmosis_inversa_link')} exact element={<PlantasOsmosisInversa />} />
          <Route path={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t('sustentec_tanques_y_depositos_modulares_link')} exact element={<TanquesDepositosModulares />} />
          {/* <Route path='/sustentec/potabilizacion_y_purificacion/bebederos_y_estaciones_de_agua' exact element={<BebederosEstacionesAgua />} /> */}
          <Route path={'/sustentec/'+t("potabilizacion_y_purificacion_link")+'/'+t('sustentec_bombeo_e_instrumentacion_link')} exact element={<BombeoInstrumentacion />} />
          <Route path={'/'+t('consultoria_link')} exact element={<Consultoria />} />
          <Route path={'/'+t('agua_link')} exact element={<Agua />} />
          <Route path={'/'+t('agentes_quimicos_link')} exact element={<AgenteQuimicos />} />
          <Route path={'/'+t('residuos_link')} exact element={<Residuos />} />
          <Route path={'/'+t('restaurantes_y_cocina_industrial_link')} exact element={<RestaurantesCocinaIndustrial />} />
          <Route path={'/'+t('mineria_link')} exact element={<Mineria />} />
          <Route path={'/'+t('descargables_link')} exact element={<Descargables />} />
          <Route path={'/'+t('contenido_audiovisual_link')} exact element={<ContenidoAudiovisual />} />
          <Route path={'/'+t("sugerencias_link")} exact element={<Sugerencias />} />
          {/* <Route path='/:section' exact element={<Content />} /> */}
          {/* <Route path='/:section' exact element={<Modelo />} /> */}
        </Routes>
        <Footer />
        <HelperAvatar />
      </Router>
    </>
  );
}

export default App;
