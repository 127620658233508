import React from 'react';
import './HeroSection.css';
import mining from '../../assets/mining-icon.webp';
import consul from '../../assets/consul-icon.webp';
import rest from '../../assets/restaurant-icon.webp';
import chemestry from '../../assets/chemestry-icon.webp';
import water from '../../assets/water-icon.webp';
import waste from '../../assets/waste-icon.webp';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

function HeroSection() {
    const { t } = useTranslation();
    return (
        <div className='container expertise-bg'>
            <div className='row text-center'>
                <div className='primaryTitle text-center w-100'>{t('somos_expertos_en') }</div>
            </div>
            <div className='row'>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={consul} />
                        <h3>{t('consultoria') }</h3>
                        <h4>+</h4>
                        <Link to={"/"+t('consultoria_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={mining} />
                        <h3>{t('mineria') }</h3>
                        <h4>+</h4>
                        <Link to={"/"+t('mineria_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={rest} />
                        <h3>{t('restaurantes_y_cocina_industrial')}</h3>
                        <h4>+</h4>
                        <Link to={ '/'+t('restaurantes_y_cocina_industrial_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={chemestry} />
                        <h3>{t('agentes_quimicos')}</h3>
                        <h4>+</h4>
                        <Link to={"/"+t('agentes_quimicos_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={water} />
                        <h3>{t('agua') }</h3>
                        <h4>+</h4>
                        <Link to={"/"+t('agua_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
                <div className='col col-3 text-center'>
                    <div className='layout-transparent'>
                        <img src={waste} />
                        <h3>{t('residuos') }</h3>
                        <h4>+</h4>
                        <Link to={"/"+t('residuos_link')}> {t('ver_mas') }</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection