import React from 'react';
import '../../Pages.css';
import tanque from '../../../../assets/tanque.jpg';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

export default function TanquesDepositosModulares() {
    const { t, i18n, ready } = useTranslation();

    return (
        <div className='container pages'>
            <div className='wrapSection'>
                <div className='row grid container justify'>
                    <h1 className='primaryTitle'>{t('tanques_y_depositos_modulares')}</h1>
                    <img src={tanque} alt={t('tanques_y_depositos_modulares')} />
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-left justify'>
                        {parse(t('tanques_y_depositos_modulares_text'))}
                    </div>
                    <div className='col-30-70'>
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('aplicaciones')}</h1>
                        {parse(t('aplicaciones_text'))}
                        
                    </div>
                    <div className='col-30-70'>
                    </div>
                </div>
                <div className='row marginTop20'>
                    <div className='grid container col-70-30 algin-content-right justify'>
                        <h1>{t('ventajas')}</h1>
                        {parse(t('tanques_ventajas_text'))}
                    </div>
                    <div className='col-30-70'>
                    </div>
                </div>
            </div>
        </div>
    )
}