import React from "react";
import { useTranslation } from 'react-i18next';
import "./Pages.css";

export default function Descargables() {
	const [files, setFiles] = React.useState(null);
	const [company, setCompany] = React.useState(-1);
	const [selectedFile, setSelectedFile] = React.useState(null);
	const { t, i18n, ready } = useTranslation();
	React.useEffect(function effectFunction() {
		fetch(window.location.origin + "/descargables/files.json")
			.then((response) => response.json())
			.then((data) => {
				var result = Object.keys(data).map((key) => [key, data[key]]);
				setFiles(result);
			});
	}, []);
	const FilesContent = () => {
		if (company == -1)
			return (
				<select disabled className="selectOpt">
					<option key={-1} value={-1}>
						{" "}
						{t('seleccionar_archivo')}
					</option>
				</select>
			);
		else
			return (
				<select
					className="selectOpt"
					onChange={(event) =>
						setSelectedFile(files[company][1][event.target.value])
					}
				>
					{files[company][1].map((item, index) => {
						return (
							<option key={index} value={index}>
								{item.caption.sp}
							</option>
						);
					})}
				</select>
			);
	};

	const DownloadButton = () => {
		if (selectedFile)
			return (
				<a
					href={window.location.origin + "/descargables/" + selectedFile.file}
					target={"_blank"}
					rel="noreferrer"
				>
					{t('download')}
				</a>
			);
		return <></>;
	};

	if (files == null) return <p>{t('loading')}...</p>;
	else {
		return (
			<div className="container pages descargables">
				<div className="wrapSection">
					<div className="row grid container justify">
						<h1 className="primaryTitle">{t('downloadables')}</h1>
						<>
							<select
								className="selectOpt"
                                onChange={(event) => { setCompany(event.target.value);  event.target.value>-1? setSelectedFile(files[event.target.value][1][0]): setSelectedFile(null)}}
							>
								<option key={-1} value={-1}>
									{t('seleccionar_empresa')}
								</option>
								{files.map((content, index) => {
									return (
										<option key={index} value={index}>
											{" "}
											{content[0]}
										</option>
									);
								})}
							</select>
						</>
						<FilesContent />
						<DownloadButton />
					</div>
				</div>
			</div>
		);
	}
}
